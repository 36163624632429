<template>
  <div class="overflow-visible z-[4]" :class="{ 'w-full': fullWidth }">
    <TailwindComponentsLoading
      v-if="!data"
      fullScreen
    ></TailwindComponentsLoading>
    
    <table class="table w-full h-full">
      <div v-if="data && data.length === 0" class="text-center p-4">
        {{ $t('No Data Available') }}
      </div>
      <thead>
        <tr>
          <template v-for="column in columns">
            <th
              :key="column.field"
              :class="{ '!p-0': isSmall }"
              v-if="column.display"
            >
              <BaseButton
                v-if="column.sortable"
                @click="handleColumnClick(column)"
                small
                :type="'text'"
                :label="column.label"
                icon-right
                :icon="getSortIcon(column)"
                class="!p-2 !pl-0 text-uppercase"
              ></BaseButton>
              <BaseCheckbox
                v-else-if="column.select && showAllSelection"
                :value="areAllSelected"
                @input="selectAll"
                class="text-uppercase"
                :disabled="disabled"
              ></BaseCheckbox>
              <span v-else-if="column.customHeader" slot="customHeader-field">
                <slot :name="column.slotNameHeader"></slot>
              </span>
              <BaseButton
                class="!p-2 !pl-0 text-uppercase"
                small
                :type="'text'"
                disabled
                :label="column.label"
                v-else
              >
              </BaseButton>
              <div v-if="column.searchable">
                <input
                  type="text"
                  v-model="searchText[column.field]"
                  class="form-control app-input rounded"
                  placeholder="Search"
                />
              </div>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, index) in sortedRows"
          :key="row.id"
          @click="handleRowClick(row)"
          class="whitespace-nowrap overflow-ellipsis max-w-xs"
          :class="{
            'cursor-not-allowed':
              restrictSelection &&
              row[restrictSelection?.field] === restrictSelection?.value,
            'cursor-pointer':
              selectable &&
              !(
                restrictSelection &&
                row[restrictSelection?.field] === restrictSelection?.value
              ),
            'app-table__row--selected':
              row === highlightRow || (!highlightRow && row === sortedRows[0]),
          }"
        >
          <template v-for="column in columns">
            <td
              v-if="column.display"
              :key="column.field"
              @click="handleCellClick(row, column)"
              :class="[column.class, { '!p-0': isSmall }]"
            >
              <div v-if="column.avatar" class="avatar px-6 py-4">
                <div class="mask mask-squircle w-12 h-12">
                  <img :src="row[column.field]" alt="Avatar" />
                </div>
              </div>
              <div v-else-if="column.tag" class="badge">
                {{ row[column.field] }}
              </div>
              <div v-else-if="column.detailed" class="px-6 py-4">
                <label
                  for="table-drawer"
                  class="btn btn-ghost drawer-button"
                  @click="setDetailRow(row, column.field)"
                  >{{ column.label }}</label
                >
              </div>
              <div v-else-if="column.dropdown && !$features.invitations">
                <select
                  :id="column.field"
                  :name="column.field"
                  :placeholder="column.label"
                  :value="row['userCollection'][column.field]"
                  @input="
                    $emit('setAs', {
                      data: row,
                      event: $event.target.value,
                    })
                  "
                  class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option
                    v-for="option in column.options"
                    @selected="$emit('setAs', { data: row, event: $event })"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
              <span
                v-else-if="column.custom"
                slot="custom-field"
                class="flex flex-wrap gap-y-1"
              >
                <slot
                  :name="column.slotName"
                  :row="row"
                  :column="column"
                  :index="index"
                  :currentPage="currentPage"
                  :itemsPerPage="itemsPerPage"
                ></slot>
              </span>
              <div v-else-if="column.action">
                <!-- v-if="!row.default" -->
                <BaseButton
                  @click="
                    $emit(column.action.toLowerCase(), {
                      data: row,
                      index: (currentPage - 1) * localItemsPerPage + index,
                    })
                  "
                  :micro="isSmall"
                  :label="column.icon ? '' : column.action"
                  :symbol="column.icon"
                  icon-small
                  :type="'text'"
                  class="p-0"
                  :for="column.action"
                  as-label
                ></BaseButton>
              </div>
              <div v-else-if="column.header1">
                <div class="flex text-primary font-bold">
                  {{ row[column.header1] }}
                  {{ row[column.header2] }}
                </div>
                <div>
                  {{ row[column.field] }}
                </div>
              </div>
              <span v-else>{{ truncate(row[column.field], 40) }}</span>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
    <BasePagination
      style="margin-top: 0rem !important"
      v-if="showPagination && data.length > itemsPerPage"
      :total="parseInt(totalPages)"
      :current.sync="currentPage"
      :showPerPage="true"
      :perPage="localItemsPerPage"
      @changePage="currentPage = $event"
      @update:perPage="handlePerPageUpdate"
      :withAnimation="false"
    ></BasePagination>

    <div v-if="detailed" class="drawer-side">
      <label for="table-drawer" class="drawer-overlay"></label>
      <ul class="menu w-1/2 bg-base-100">
        <slot :row="detailRow" :field="detailField" name="detail" />
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    restrictSelection: Object,
    isSmall: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    showAllSelection: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
      required: false,
    },
    app: {
      type: String,
      default: '',
    },
    sortOrderProp: {
      type: String,
    },
    sortFieldProp: {
      type: String,
    },
    columns: {
      type: Array,
      required: true,
    },
    backendSorting: {
      type: Boolean,
      default: false,
    },
    backendPagination: {
      type: Boolean,
      default: false,
    },
    backendSearching: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 12,
    },
    itemsPerPage: {
      type: Number,
      default: 12,
    },
    setCurrentPage: {
      type: Number,
      default: null,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    areAllSelected: {
      type: Boolean,
      default: false,
    },
    detailed: Boolean,
    perPageOptions: {
      type: Array,
      default: () => [10, 25, 50, 100],
    },
    selectedRow: {
      default: null,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    if (this.sortOrderProp) {
      this.sortOrder = this.sortOrderProp
    }
    if (this.sortFieldProp) {
      this.sortField = this.sortFieldProp
    }
    if (this.setCurrentPage) {
      this.currentPage = this.setCurrentPage
    }
  },
  data() {
    return {
      sortOrder: 'asc',
      sortField: '',
      searchText: {},
      sortColumn: '',
      filteredRows: [],
      detailRow: null,
      detailField: null,
      currentPage: 1,
      localItemsPerPage: this.itemsPerPage,
      highlightRow: null,
    }
  },
  watch: {
    searchText: {
      handler(newVal, oldVal) {
        if (this.backendSearching) {
          this.$emit('searchBackend', {
            rows: this.data,
            search: this.searchText,
          })
        }
      },
      deep: true,
      immediate: true,
    },
    itemsPerPage(newVal) {
      this.localItemsPerPage = newVal
    },
  },
  methods: {
    selectAll(value) {
      this.$emit('selectAll', value)
    },
    truncate(str, n) {
      if (typeof str === 'string') {
        const truncateText = useTruncate(str, n)
        return truncateText
      } else {
        return str
      }
    },
    handleColumnClick(column) {
      if (!column.sortable) {
        return
      }

      if (this.sortOrder === 'asc') {
        this.sortOrder = 'desc'
      } else if (this.sortOrder === 'desc') {
        this.sortOrder = 'asc'
      }

      this.sortField = column.field

      if (this.backendSorting) {
        this.$emit('backendSorting', {
          field: this.sortField,
          order: this.sortOrder,
        })
      }
    },

    getSortIcon(column) {
      if (!column.sortable || this.sortField !== column.field) {
        return ''
      }
      return this.sortOrder === 'asc' ? 'sort-ascending' : 'sort-descending'
    },
    handlePageChange(page) {
      this.currentPage = page
      if (this.backendPagination) {
        return this.$emit('backendPagination', page)
      }
    },
    handlePerPageUpdate(newPerPage) {
      this.localItemsPerPage = newPerPage
      this.$emit('updatePerPage', newPerPage)
    },
    handleRowClick(row) {
      if (
        this.restrictSelection &&
        row[this.restrictSelection?.field] === this.restrictSelection?.value
      ) {
        return
      } else {
        this.highlightRow = row
        this.$emit('clickRow', row)
      }
    },
    handleCellClick(row, column) {
      this.$emit('clickCell', row, column)
    },

    setDetailRow(row, field) {
      this.detailRow = row
      this.detailField = field
    },
    filterRows(rows) {
      if (this.backendSearching) {
        return rows
      }
      return rows.filter(row => {
        let match = true
        for (let field in this.searchText) {
          if (row[field] === undefined) {
            continue
          }
          const fieldValue = row[field].toString().toLowerCase()
          const searchText = this.searchText[field].toLowerCase()
          if (!fieldValue.includes(searchText)) {
            match = false
            break
          }
        }
        return match
      })
    },
  },
  computed: {
    totalPages() {
      if (this.backendPagination) {
        return this.total
      }
      console.log("🚀 ~ totalPages ~ this.sortedRows:", this.sortedRows)

      return this.sortedAndFilteredRows.length
    },
    sortedAndFilteredRows() {
      if (!this.data) {
        return []
      }
      let rows = [...this.data]

      // Apply filtering
      rows = this.filterRows(rows)

      // Apply sorting if not backend sorted
      if (this.sortField && this.sortOrder && !this.backendSorting) {
        const tableSort = useTablesort(rows, this.sortField, this.sortOrder)
        rows = tableSort
      }

      return rows
    },
    sortedRows() {
      // Apply pagination slice
      const startIndex = (this.currentPage - 1) * this.localItemsPerPage
      const endIndex = startIndex + this.localItemsPerPage
      return this.sortedAndFilteredRows.slice(startIndex, endIndex)
    },
  },
}
</script>

<style scoped>
.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 0.75rem;
}

.table th {
  text-align: left;
  background-color: white;
}

.table td {
  font-size: 0.875rem;
  line-height: 1.5;
  @apply align-middle;
}
</style>
